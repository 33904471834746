// import { Trans } from '@lingui/react';
import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router';
import AuthApi from '../api/AuthApi';
import FormComponent from '../components/FormComponent';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../constants';
import { AuthContext } from '../context/AuthContext';

const MyProfile = FormComponent({
    displayName: 'MyProfile',
    withoutNavigation: true,
    schema: {
        title: "Mi Perfil",
        type: "object",
        properties: {
            firstName: { title: "Nombre", type: "string" },
            lastName: { title: "Apellido", type: "string" },
            email: { title: "Email", type: "string" },
            password: { title: "Password", type: "string" },
            passwordConfirm: { title: "Confirm Password", type: "string" },
            roleId: {
                title: "Rol",
                type: "number",
                lookup: {
                    api: 'roles',
                    resource: "Roles",
                    id: 'id',
                    label: 'displayName'
                }
            },
            branchId: {
                title: "Oficina",
                type: "number",
                lookup: {
                    api: 'branches',
                    resource: "Oficinas",
                    id: 'id',
                    label: 'name'
                }
            },
            identificationNumber: { title: "Número de Cédula", type: "string" },
            licenseNumber: { title: "Número de Licencia", type: "string" },
            isActive: {
                title: "Activo",
                type: "boolean"
            }
        },
        required: ["firstName", "lastName", "email", "roleId", "branchId"],
        dependencies: {
            password: ["passwordConfirm"]
        }
    },
    uiSchema: {
        "classNames": "smallTwoRows",
        "password": { "classNames": "from-col-1", "ui:widget": "password" },
        "passwordConfirm": { "ui:widget": "password" },
        "branchId": { "ui:field": "LookupFormField", "ui:readonly": true },
        "roleId": { "classNames": "from-col-1", "ui:field": "LookupFormField", "ui:readonly": true },
        "isActive": { "classNames": "from-col-1", "ui:readonly": true },
    },
    validate({password, passwordConfirm}, errors){
        if (password && password !== passwordConfirm) {
            errors.passwordConfirm.addError("Password es diferente.");
        }
        return errors;
    },
    loadData: () => AuthApi.getSelf(),
    async onSubmit({ formData }) {
        await AuthApi.updateSelf(formData);
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON, 
});

export default MyProfile;