import React, { useMemo, useState } from "react";
import { FormGroup, Button } from "reactstrap";
import CasesApi from "../../api/CasesApi";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import Loader from '../Loader';
import Notification from "../Notification";


function FetchClientInfoButton(props) {
    const {
        formContext,
        schema: {
            title,
        },
        uiSchema: {
            'ui:readonly': readonly
        },
    } = props;
    const id = formContext.fileField.postArgs.caseId || -1;
    const [isLoading, setIsLoading] = useState(false);
    const [newError, setError] = useState("");
    const { sideChannel } = formContext;
    const formObject = useSideChannelSubscription(sideChannel, 1) || [{}];
    async function fetchData(e) {
        e.preventDefault();
        if (isLoading) return;
        const clientType = formObject.clients[0].clientTypeId;
        let isBusiness = false;
        if (clientType !== 1 && clientType !== 4) {
            isBusiness = true;
        }

        const initialInfoBusiness = formObject.clients[0].businessClientBasicInfo
        const initialInfoClient = formObject.clients[0].retailClientBasicInfo

        const idType = isBusiness ? 1 : initialInfoClient.identificationType;

        const idString = !isBusiness ? (idType === 1 && initialInfoClient.identificationNumber ? initialInfoClient.identificationNumber :
            idType === 2 && initialInfoClient.passportNumber ? initialInfoClient.passportNumber :
                idType === 3 && initialInfoClient.foreignIdNumber ? initialInfoClient.foreignIdNumber : "") : initialInfoBusiness.identificationNumber

        if (idString === "") return;

        setIsLoading(true);
        let data;

        try {
            setError("");
            data = await CasesApi.getDataClient({ clientType, idString, idType, id });
            
        } catch (error) {
            setIsLoading(false);
            if (error.message === "Not Found") {
                setError("No se encontró cliente con este ID.");
            }
            else {
                setError(error.message);
            }

        }

        const dataSet = setClientData(data, isBusiness);

        formContext.setFormDataValues(dataSet)
        setIsLoading(false);
    }

    return (
        <>
            {newError ? (<Notification color="danger">
                <div>{newError}</div>
            </Notification>) : null}
            {!readonly ? <FormGroup className="file-field" disabled={readonly || isLoading}><Button
                className="btn btn-secondary"
                target="_blank"
                onClick={(e) => fetchData(e)}
            >{isLoading ? <Loader /> : title}</Button></FormGroup> : <></>}
        </>

    );
}

function setClientData(data, isBusiness) {
    return !isBusiness ? {
        "clientSchema.nombre": data.clients[0].retailClientBasicInfo.firstName,
        "clientSchema.apellidos": data.clients[0].retailClientBasicInfo.lastName,
        "clientSchema.segundoNombre": data.clients[0].retailClientBasicInfo.secondName,
        "clientSchema.email": data.clients[0].retailClientBasicInfo.email,
        "clientSchema.sexo": data.clients[0].retailClientBasicInfo.sex,
        "clientSchema.telefono": data.clients[0].retailClientBasicInfo.phone,
        "clientSchema.celular": data.clients[0].retailClientBasicInfo.phoneMobile,
        "clientSchema.otro": data.clients[0].retailClientBasicInfo.phoneOther,
        "clientSchema.estadoCivil": data.clients[0].retailClientBasicInfo.civilStatus,
        "clientSchema.comentarios": data.clients[0].retailClientBasicInfo.comments,
        // InfoAdicional
        "clientAditionalInfoSchema.fechaNacimiento": data.clients[0].retailClientBasicInfo.dateOfBirth,
        "clientAditionalInfoSchema.lugarNacimiento": data.clients[0].retailClientBasicInfo.countryOfBirth,
        "clientAditionalInfoSchema.nacionalidad": data.clients[0].retailClientBasicInfo.nationality,
        "clientAditionalInfoSchema.direccionResidencia": data.clients[0].retailClientBasicInfo.address,
        "clientAditionalInfoSchema.sector": data.clients[0].retailClientBasicInfo.addressSector,
        "clientAditionalInfoSchema.ciudad": data.clients[0].retailClientBasicInfo.city,
        "clientAditionalInfoSchema.pais": data.clients[0].retailClientBasicInfo.country,
        "clientAditionalInfoSchema.residenteRD": data.clients[0].retailClientBasicInfo.residentOfDominicanRepublic,
        "clientAditionalInfoSchema.residenciaFisicaUSA": data.clients[0].retailClientBasicInfo.isAmericanResident,
        "clientAditionalInfoSchema.estatusMigratorio": data.clients[0].retailClientFatcaInfo.unitedStatesImmigrationStatus,
        "clientAditionalInfoSchema.profesion": data.clients[0].retailClientBasicInfo.profession,
        "clientAditionalInfoSchema.ocupacion": data.clients[0].retailClientBasicInfo.occupation,
        "clientAditionalInfoSchema.ingresosMensuales": data.clients[0].retailClientBasicInfo.monthlyIncome,
        "clientAditionalInfoSchema.zonaGeograficaNacional": data.clients[0].retailClientBasicInfo.nationalGeographicZone,
        "clientAditionalInfoSchema.personaExpuestaPoliticamente": data.clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposure,
        "clientAditionalInfoSchema.parentescoPersonaExpuestaPoliticamente": data.clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative,
        "clientAditionalInfoSchema.sujetoObligadoLey155": data.clients[0].retailClientBasicInfo.isSubjectToLaw15517,
        "clientAditionalInfoSchema.vinculadoJMMB": data.clients[0].retailClientBasicInfo.isRelatedToFranchise,
        "clientAditionalInfoSchema.beneficiariosDeTransaccion": data.clients[0].retailClientBasicInfo.hasBeneficiaries,
        "clientAditionalInfoSchema.nombreBeneficiario": data.clients[0].retailClientBasicInfo.beneficiaryName,
        "clientAditionalInfoSchema.ocupacionOtro": data.clients[0].retailClientBasicInfo.otherOccupation,
        "clientAditionalInfoSchema.indicarPaises": data.clients[0].retailClientBasicInfo.otherNationalities,
        "clientAditionalInfoSchema.nacionalidadOtroPais": data.clients[0].retailClientBasicInfo.hasOtherNationality,
        "clientAditionalInfoSchema.tipoSujetoObligado": data.clients[0].retailClientBasicInfo.obligationType,
        "clientAditionalInfoSchema.ingresosActividadesFormales": data.clients[0].retailClientBasicInfo.isIncomeFromFormalActivities,
        "clientAditionalInfoSchema.otraFuenteIngresosFormales": data.clients[0].retailClientBasicInfo.hasOtherSourcesOfIncome,
        "clientAditionalInfoSchema.idBeneficiario": data.clients[0].retailClientBasicInfo.beneficiaryId,
        "clientAditionalInfoSchema.apellidoBeneficiario": data.clients[0].retailClientBasicInfo.beneficiaryLastName,
    } :
    {
        "comercialSchema.nombreEmpresa": data.clients[0].businessClientBasicInfo.businessName,
        "comercialSchema.actividadPrincipal": data.clients[0].businessClientBasicInfo.businessMainActivity,
        "comercialSchema.tipoIdentificacionComercial": data.clients[0].businessClientBasicInfo.identificationType,
        "comercialSchema.nombreContacto": data.clients[0].businessClientBasicInfo.contactFirstName,
        "comercialSchema.apellidoContacto": data.clients[0].businessClientBasicInfo.contactLastName,
        "comercialSchema.posicionContacto": data.clients[0].businessClientBasicInfo.contactPosition,
        "comercialSchema.telefono": data.clients[0].businessClientBasicInfo.phone,
        "comercialSchema.celular": data.clients[0].businessClientBasicInfo.phoneMobile,
        "comercialSchema.email": data.clients[0].businessClientBasicInfo.contactEmail,
        "comercialSchema.comentarios": data.clients[0].businessClientBasicInfo.comments,
        "comercialSchema.numIdentificacionRNC": data.clients[0].businessClientBasicInfo.identificationNumber,
        // REPRESENTANTE LEGAL
        "infoRepLegal.nombreRep" : data.clients[0].businessClientBasicInfo.legalRepresentativeFirstName,
        "infoRepLegal.apellidoRep" : data.clients[0].businessClientBasicInfo.legalRepresentativeLastName,
        "infoRepLegal.posRep" : data.clients[0].businessClientBasicInfo.legalRepresentativePosition,
        "infoRepLegal.tipoId" : data.clients[0].businessClientBasicInfo.legalRepresentativeIdType,
        "infoRepLegal.numeroId" : data.clients[0].businessClientBasicInfo.legalRepresentativeIdNumber,
        "infoRepLegal.emailRep" : data.clients[0].businessClientBasicInfo.legalRepresentativeEmail,
        "infoRepLegal.telefonoRep" : data.clients[0].businessClientBasicInfo.legalRepresentativePhone,
        "infoRepLegal.celularRep" : data.clients[0].businessClientBasicInfo.legalRepresentativeCellphone,
        // DATOS EMPRESA
        "datosEmpresa.fechaConst" : data.clients[0].businessClientBasicInfo.dateFounded,
        "datosEmpresa.lugarConst" : data.clients[0].businessClientBasicInfo.placeFounded,
        "datosEmpresa.dirResidencia" : data.clients[0].businessClientBasicInfo.address,
        "datosEmpresa.sector" : data.clients[0].businessClientBasicInfo.addressSector,
        "datosEmpresa.ciudad" : data.clients[0].businessClientBasicInfo.city,
        "datosEmpresa.pais" : data.clients[0].businessClientBasicInfo.country,
        "datosEmpresa.zonaGeograficaNacional" : data.clients[0].businessClientBasicInfo.nationalGeographicZone,
        "datosEmpresa.website" : data.clients[0].businessClientBasicInfo.businessWebsite,
        "datosEmpresa.nombreDirectores" : data.clients[0].businessClientGeneralInfo.directorNames,
        // >>>>>>>> BCGI <<<<<<<<<<
        "datosEmpresa.numEmpleados" : data.clients[0].businessClientGeneralInfo.numEmployees,
        "datosEmpresa.promMensVentas" : data.clients[0].businessClientGeneralInfo.avgMonthlySales,
        "datosEmpresa.prodServOfrecen" : data.clients[0].businessClientGeneralInfo.offering,
        "datosEmpresa.empresasAfiliadas" : data.clients[0].businessClientGeneralInfo.associatedBusinesses.map(element => {
            return {
                "nombreRazonSocial": element.nameOrSocialReason,
                "rncNumber": element.rncNumber,
            }
        }),
        "datosEmpresa.principalesProveedores" : data.clients[0].businessClientGeneralInfo.mainProviders.map(element => {
            return {
                "nombre": element.name,
            }
        }),
        "datosEmpresa.principalesAccionistas.princAccionList" : data.clients[0].businessClientGeneralInfo.mainInvestors.map(element => {
            return {
                "nombreCompleto": element.name,
                "participacionAccionaria" : element.shareholding,
                "nacionalidad" : element.nationality,
                "ciudadaniaUSA" : element.hasUnitedStatesCitizenship,
                "numeroIdent" : element.identificationNumber,
                "benefFinal" : element.finalBeneficiary,
            }
        }),
        "datosEmpresa.miembrosOrganoDireccion" : data.clients[0].businessClientGeneralInfo.businessClientBoardMembers.map(element => {
            return {
                "nombreCompleto": element.boardMemberName,
                "posicion" : element.boardMemberPosition,
                "nacionalidad" : element.boardMemberNationality,
                "numeroIdent" : element.boardMemberIdNumber,
            }
        }),
    };
}


export default FetchClientInfoButton;